.index-page {
    max-width: 100%;
    overflow: hidden;
    background-color: #fff;
    padding-top: 80px
}

.scope-banner-module {
    padding: 13px 14px 0;
    position: relative;
    width: 100%;
}

.scope-banner-module .scope-banner-module-info {
    left: 50%;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%)
}

.scope-banner-module .scope-banner-module-info .scope-banner-module-info-p1 {
    color: #fff;
    font-family: HarmonyOSHans-Regular, PingFangSC-Regular, Microsoft YaHei, Arial, Helvetica, sans-serif;
    font-size: 80px;
    font-weight: 600;
    letter-spacing: 1.44px;
    line-height: 100%;
    margin-bottom: 32px
}

.scope-banner-module .scope-banner-module-info .scope-banner-module-info-p2 {
    color: #fff;
    font-family: HarmonyOSHans-Regular, PingFangSC-Regular, Microsoft YaHei, Arial, Helvetica, sans-serif;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: .02em;
    line-height: 160%;
    margin-bottom: 32px
}

.scope-banner-module .scope-banner-module-info .scope-banner-module-info-button {
    align-items: center;
    background: #fff;
    border: 1px solid #e5e8ee;
    border-radius: 8px;
    box-shadow: 0 .266px .217px #1206380a, 0 .893px .766px #1206380f, 0 4px 5px #12063812, 0 .249px .387px #0e1ea805, 0 .599px .931px #0e1ea805, 0 1.127px 1.753px #0e1ea808, 0 2.01px 3.127px #0e1ea80a, 0 3.76px 5.849px #0e1ea80a;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    padding: 16px 16px 16px 24px
}

.scope-banner-module .scope-banner-module-info .scope-banner-module-info-button .scope-banner-module-info-button-span {
    color: #222;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    margin-right: 16px
}

.scope-banner-module .scope-banner-module-info .scope-banner-module-info-button .scope-banner-module-info-button-icon {
    height: 18px;
    width: 18px
}

.scope-banner-module .scope-banner-module-mouse-container {
    bottom: 28px;
    left: 50%;
    position: absolute;
    text-align: center;
    transform: translate(-50%);
    width: 100px
}

.scope-banner-module .scope-banner-module-mouse-container .scope-banner-module-mouse {
    margin-bottom: 11px
}

.scope-banner-module .scope-banner-module-mouse-container .scope-banner-module-mouse-span {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .28px;
    line-height: 160%
}

.scope-normal-module1 {
    margin: 0 auto;
    max-width: 1920px;
    padding: 160px 0 100px;
    position: relative;
    width: 100%;
    z-index: 1
}

.scope-normal-module1 .scope-normal-module1-bg {
    height: 482px;
    position: absolute;
    right: 1085px;
    top: 172px;
    width: 835px;
    z-index: -1
}

.scope-normal-module1 .scope-normal-module1-content {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    min-width: 1400px;
    width: 77.0833333333%
}

.scope-normal-module1 .scope-normal-module1-img {
    height: 586px;
    padding-left: 30px;
    width: 759px
}

.scope-normal-module1 .scope-normal-module1-info {
    min-width: 575px;
    padding: 0 20px 16px 0;
    width: 41.5540540541%
}

.scope-normal-module1 .scope-normal-module1-info .scope-normal-module1-h1 {
    color: #222;
    font-family: HarmonyOSHans-Regular, PingFangSC-Regular, Microsoft YaHei, Arial, Helvetica, sans-serif;
    font-size: 64px;
    font-weight: 600;
    line-height: 120%;
    margin-bottom: 16px;
    white-space: nowrap
}

.scope-normal-module1 .scope-normal-module1-info .scope-normal-module1-ul {
    padding: 24px 0
}

.scope-normal-module1 .scope-normal-module1-info .scope-normal-module1-ul .scope-normal-module1-li {
    color: #424552;
    font-family: HarmonyOSHans-Regular, PingFangSC-Regular, Microsoft YaHei, Arial, Helvetica, sans-serif;
    font-size: 20px;
    line-height: 1.6em;
    padding: 12px 16px 12px 0
}

.scope-normal-module2 {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-width: 1920px;
    overflow: hidden;
    padding: 100px 0 160px;
    position: relative;
    width: 100%;
    z-index: 1
}

.scope-normal-module2 .scope-normal-module2-bg {
    height: 482px;
    left: 1085px;
    position: absolute;
    top: 316px;
    width: 835px;
    z-index: -1
}

.scope-normal-module2 .scope-normal-module2-content {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    min-width: 1400px;
    width: 75%
}

.scope-normal-module2 .scope-normal-module2-img-container {
    min-width: 720px;
    padding-top: 42px;
    width: 51.3888888889%
}

.scope-normal-module2 .scope-normal-module2-img {
    height: 540px;
    width: 720px
}

.scope-normal-module2 .scope-normal-module2-info {
    padding: 15px 0 0 20px
}

.scope-normal-module2 .scope-normal-module2-info .scope-normal-module2-h1 {
    color: #222;
    font-family: HarmonyOSHans-Regular, PingFangSC-Regular, Microsoft YaHei, Arial, Helvetica, sans-serif;
    font-size: 64px;
    font-weight: 600;
    line-height: 120%
}

.scope-normal-module2 .scope-normal-module2-info .scope-normal-module2-ul {
    padding: 56px 0 0
}

.scope-normal-module2 .scope-normal-module2-info .scope-normal-module2-ul .scope-normal-module2-li {
    color: #424552;
    font-family: HarmonyOSHans-Regular, PingFangSC-Regular, Microsoft YaHei, Arial, Helvetica, sans-serif;
    font-size: 20px;
    line-height: 1.6em;
    padding: 16px 0
}

.scope-normal-module3 {
    background: linear-gradient(180deg, #fff 88.26%, #f6fafe);
    padding: 120px 0 160px;
    width: 100%
}

.scope-normal-module3 .scope-normal-module3-h1 {
    color: #222;
    font-size: 64px;
    font-weight: 600;
    line-height: 120%
}

.scope-normal-module3 .scope-normal-module3-h1,
.scope-normal-module3 .scope-normal-module3-p1 {
    font-family: HarmonyOSHans-Regular, PingFangSC-Regular, Microsoft YaHei, Arial, Helvetica, sans-serif;
    text-align: center
}

.scope-normal-module3 .scope-normal-module3-p1 {
    color: #424552;
    font-size: 20px;
    line-height: 2em;
    padding-top: 32px
}

.scope-normal-module3 .scope-normal-module3-ul {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-width: 1664px;
    min-width: 1440px;
    padding-top: 60px;
    width: 86.6666666667%
}

.scope-normal-module3 .scope-normal-module3-ul .scope-normal-module3-li {
    padding: 0 20px
}

.scope-normal-module3 .scope-normal-module3-ul .scope-normal-module3-li1 {
    height: 564px;
    /* width: 31.5656565657% */
    width: calc(100% / 3);
}

.scope-normal-module3 .scope-normal-module3-ul .scope-normal-module3-li2 {
    height: 564px;
    /* width: 35.6060606061% */
    width: calc(100% / 3);
}

.scope-normal-module3 .scope-normal-module3-ul .scope-normal-module3-li3 {
    height: 564px;
    /* width: 32.8282828283% */
    width: calc(100% / 3);
}

.scope-normal-module3 .scope-normal-module3-ul .scope-normal-module3-li-img {
    max-height: 100%;
    max-width: 100%
}

.scope-normal-module4 {
    background: linear-gradient(180deg, #f6f9fe, #ebf9fb);
    overflow: hidden;
    width: 100%
}

.scope-normal-module4 .scope-normal-module4-content {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1440px;
    min-width: 1360px;
    padding: 80px 0;
    position: relative;
    width: 75%
}

.scope-normal-module4 .scope-normal-module4-bg-img1 {
    height: 403px;
    left: -10.5555555556%;
    position: absolute;
    top: 0;
    width: 552px
}

.scope-normal-module4 .scope-normal-module4-img {
    height: 870px;
    width: 870px
}

.scope-normal-module4 .scope-normal-module4-info {
    text-align: left
}

.scope-normal-module4 .scope-normal-module4-h1 {
    color: #222;
    font-family: HarmonyOSHans-Regular, PingFangSC-Regular, Microsoft YaHei, Arial, Helvetica, sans-serif;
    font-size: 64px;
    font-weight: 600;
    line-height: 120%
}

.scope-normal-module4 .scope-normal-module4-h2 {
    font-weight: 500;
    margin-bottom: 64px;
    padding-top: 32px
}

.scope-normal-module4 .scope-normal-module4-h2,
.scope-normal-module4 .scope-normal-module4-ul .scope-normal-module4-li {
    color: #424552;
    font-family: HarmonyOSHans-Regular, PingFangSC-Regular, Microsoft YaHei, Arial, Helvetica, sans-serif;
    font-size: 20px;
    line-height: 1.6em
}

.scope-normal-module4 .scope-normal-module4-ul .scope-normal-module4-li {
    padding: 20px 16px 20px 0
}

.scope-normal-module5 {
    background: linear-gradient(180deg, #ecfafc, hsla(0, 0%, 100%, 0) 54.37%);
    padding: 80px 0;
    width: 100%
}

.scope-normal-module5 .scope-normal-module5-h1 {
    color: #222;
    font-size: 64px;
    font-weight: 600;
    line-height: 120%
}

.scope-normal-module5 .scope-normal-module5-h1,
.scope-normal-module5 .scope-normal-module5-p1 {
    font-family: HarmonyOSHans-Regular, PingFangSC-Regular, Microsoft YaHei, Arial, Helvetica, sans-serif;
    text-align: center
}

.scope-normal-module5 .scope-normal-module5-p1 {
    color: #424552;
    font-size: 20px;
    line-height: 1.6em;
    padding-top: 32px
}

.scope-normal-module5 .scope-normal-module5-ul {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1437px;
    min-width: 1400px;
    padding-top: 84px;
    width: 74.84375%
}

.scope-normal-module5 .scope-normal-module5-ul .scope-normal-module5-li {
    height: 571px;
    position: relative;
    text-align: center
}

.scope-normal-module5 .scope-normal-module5-ul .scope-normal-module5-li .scope-normal-module5-li-p1 {
    color: #686a6c;
    font-size: 20px;
    font-weight: 400;
    left: 0;
    line-height: 28px;
    position: absolute;
    text-align: center;
    top: 409px;
    width: 100%
}

.scope-normal-module5 .scope-normal-module5-ul .scope-normal-module5-li1 {
    padding-top: 113.37px
}

.scope-normal-module5 .scope-normal-module5-ul .scope-normal-module5-li1 .scope-normal-module5-li1-img {
    height: 245px;
    width: 387px
}

.scope-normal-module5 .scope-normal-module5-ul .scope-normal-module5-li2 {
    background-color: #f6f7fa;
    border-radius: 40px;
    padding-top: 111.59px;
    width: 453px
}

.scope-normal-module5 .scope-normal-module5-ul .scope-normal-module5-li2 .scope-normal-module5-li2-img {
    height: 242px;
    width: 329px
}

.scope-normal-module5 .scope-normal-module5-ul .scope-normal-module5-li3 {
    padding-top: 132.37px
}

.scope-normal-module5 .scope-normal-module5-ul .scope-normal-module5-li3 .scope-normal-module5-li3-img {
    height: 243px;
    width: 363px
}

.scope-normal-module5 .scope-normal-module5-ul .scope-normal-module5-li-img {
    display: inline-block
}

.scope-normal-module6 {
    height: 691px;
    margin: 0 auto;
    max-width: 1920px;
    padding-bottom: 20px;
    position: relative;
    width: 100%
}

.scope-normal-module6 .scope-normal-module6-lines {
    left: 50%;
    position: absolute;
    top: 35px;
    transform: translate(-50%);
    width: 1920px
}

.scope-normal-module6 .scope-normal-module6-Ellipse1 {
    background: #4c48ef;
    border-radius: 372.076px;
    filter: blur(100px);
    flex-shrink: 0;
    height: 372.076px;
    left: 60.3791666667%;
    margin-left: -94px;
    opacity: .5;
    position: absolute;
    top: 184.5px;
    transform: rotate(-85.193deg);
    width: 188.51px
}

.scope-normal-module6 .scope-normal-module6-Ellipse2 {
    background: #82fff9;
    border-radius: 279.468px;
    filter: blur(93.9586791992px);
    flex-shrink: 0;
    height: 114.583px;
    left: 43.7005208333%;
    margin-left: -140px;
    opacity: .5;
    position: absolute;
    top: 356.75px;
    transform: rotate(-149.667deg);
    width: 279.468px
}

.scope-normal-module6 .scope-normal-module6-line-right {
    min-width: 458px;
    position: absolute;
    right: 0;
    top: 232px;
    width: 31.7708333333%
}

.scope-normal-module6 .scope-normal-module6-line-left {
    left: 0;
    min-width: 458px;
    position: absolute;
    top: 232px;
    width: 31.7708333333%
}

.scope-normal-module6 .scope-normal-module6-info {
    left: 50%;
    position: absolute;
    text-align: center;
    top: 206px;
    transform: translate(-50%)
}

.scope-normal-module6 .scope-normal-module6-info .scope-normal-module6-h1 {
    color: #222;
    font-family: PingFang SC;
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    margin-bottom: 47px;
    text-align: center
}

.scope-normal-module6 .scope-normal-module6-info .scope-normal-module6-h2 {
    align-items: center;
    display: flex
}

.scope-normal-module6 .scope-normal-module6-info .scope-normal-module6-h2 .scope-normal-module6-h2-point {
    background: #6663fa;
    border-radius: 12px;
    height: 8px;
    margin: 0 16px;
    width: 8px
}

.scope-normal-module6 .scope-normal-module6-info .scope-normal-module6-h2 .scope-normal-module6-h2-point:first-child {
    margin-left: 0
}

.scope-normal-module6 .scope-normal-module6-info .scope-normal-module6-h2 .scope-normal-module6-h2-span {
    color: #424552;
    font-family: HarmonyOSHans-Light, PingFangSC-Regular, Microsoft YaHei, Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 140%
}

.scope-normal-module7 {
    height: 588px;
    position: relative;
    width: 100%
}

.scope-normal-module7 .scope-normal-module7-img-container {
    height: 100%;
    left: -1000px;
    margin: 0 auto;
    position: absolute;
    right: -1000px;
    top: 0;
    width: 1920px
}

.scope-normal-module7 .scope-normal-module7-img {
    align-items: center;
    display: flex;
    height: 861px;
    justify-content: flex-end;
    position: absolute;
    right: 124px;
    top: -46px;
    width: 861px
}

.scope-normal-module7 .scope-normal-module7-img>img {
    width: 638px
}

.scope-normal-module7 .scope-normal-module7-content {
    left: 0;
    min-width: 740px;
    position: absolute;
    top: 130px;
    width: 48.4375%
}

.scope-normal-module7 .scope-normal-module7-content .scope-normal-module7-info {
    float: right;
    text-align: left
}

.scope-normal-module7 .scope-normal-module7-content .scope-normal-module7-info .scope-normal-module7-h1 {
    color: #222;
    font-family: HarmonyOSHans-Regular, PingFangSC-Regular, Microsoft YaHei, Arial, Helvetica, sans-serif;
    font-size: 64px;
    font-weight: 600;
    line-height: 84px;
    margin-bottom: 24px
}

.scope-normal-module7 .scope-normal-module7-content .scope-normal-module7-info .scope-normal-module7-p1 {
    color: #424552;
    font-family: HarmonyOSHans-Regular, PingFangSC-Regular, Microsoft YaHei, Arial, Helvetica, sans-serif;
    font-size: 20px;
    line-height: 1.6em;
    margin-bottom: 71px
}

.scope-normal-module7 .scope-normal-module7-content .scope-normal-module7-info .scope-normal-module7-list-img {
    width: 640px
}

.scope-normal-module8 {
    align-items: center;
    display: flex;
    height: 804px;
    justify-content: space-around;
    margin: 0 auto;
    position: relative;
    width: 100%
}

.scope-normal-module8 .scope-normal-module8-img-container {
    height: 100%;
    left: -1000px;
    margin: 0 auto;
    max-width: 1962px;
    min-width: 1722px;
    position: absolute;
    right: -1000px;
    top: 0;
    width: 102.1875%
}

.scope-normal-module8 .scope-normal-module8-img {
    height: 638px;
    left: 193px;
    position: absolute;
    top: 68px;
    width: 638px
}

.scope-normal-module8 .scope-normal-module8-content {
    min-width: 658px;
    position: absolute;
    right: 0;
    text-align: left;
    top: 328px;
    width: 43.6458333333%
}

.scope-normal-module8 .scope-normal-module8-content .scope-normal-module8-h1 {
    color: #222;
    font-family: HarmonyOSHans-Regular, PingFangSC-Regular, Microsoft YaHei, Arial, Helvetica, sans-serif;
    font-size: 64px;
    font-weight: 600;
    line-height: 84px;
    margin-bottom: 24px
}

.scope-normal-module8 .scope-normal-module8-content .scope-normal-module8-p1 {
    color: #424552;
    font-family: HarmonyOSHans-Regular, PingFangSC-Regular, Microsoft YaHei, Arial, Helvetica, sans-serif;
    font-size: 20px;
    line-height: 1.6em
}

.scope-normal-module9 {
    padding: 0 0 100px 0;
    text-align: center;
    width: 100%
}

.scope-normal-module9 .scope-normal-module9-h1 {
    color: #222;
    font-family: HarmonyOSHans-Regular, PingFangSC-Regular, Microsoft YaHei, Arial, Helvetica, sans-serif;
    font-size: 64px;
    font-weight: 600;
    line-height: 84px;
    margin-bottom: 48px
}

.scope-normal-module9 .scope-normal-module9-img {
    margin: 0 auto;
    width: 1440px
}

.home-banner {
    /* background: #6663ff; */
    border-radius: 10px;
    /* height: calc(100vh - 107px); */
    overflow: hidden;
    position: relative;
    width: 100%;
}

.home-banner-photo-left {
    background-blend-mode: hard-light;
    background-image: conic-gradient(#f523fe, #2824ff);
    background-position-x: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: -45.4255319149%;
    height: 198.1858510638%;
    left: -52.0359598096%;
    margin: auto;
    opacity: .7;
    position: absolute;
    right: 14.7540983607%;
    top: -111.0638297872%;
    width: 95.4022739291%
}

.home-banner-photo-right,
.home-banner-photo-right2 {
    animation-timing-function: linear;
    background-blend-mode: hard-light;
    background-image: conic-gradient(#5b58ff, #36d3fe);
    background-position-x: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: -42.3404255319%;
    height: 152.5141489362%;
    left: 25.594923321%;
    margin: auto;
    position: absolute;
    right: -39.9788471708%;
    top: -84.7872340426%;
    width: 92.7740349022%
}

.home-banner-photo-right2 {
    animation-name: animate-right2-2854adfd
}

.home-banner-mouse {
    display: inline-block;
    height: 48px;
    position: relative;
    width: 25px
}

.home-banner-mouse-border {
    border: 1px solid #fff;
    border-radius: 12px;
    height: 100%;
    width: 100%
}

.home-banner-mouse-point {
    animation-delay: 50ms;
    animation-direction: alternate;
    animation-duration: .7s;
    animation-iteration-count: infinite;
    animation-name: animate-6aa4c19a;
    animation-timing-function: ease-in-out;
    background-color: #c4ffff;
    border-radius: 50%;
    height: 5px;
    left: 50%;
    position: absolute;
    top: 37.5px;
    width: 5px
}

@keyframes animate-6aa4c19a {
    0% {
        transform: translate3d(-50%, -15px, 0)
    }

    to {
        transform: translate3d(-50%, 0, 0)
    }
}

.scope-normal-module10 {
    box-sizing: border-box;
    padding: 0 14px;
    width: 100%
}

.scope-normal-module10 .scope-normal-module10-content {
    background: linear-gradient(180deg,#eff3ff,#f3fcfb);
    border-radius: 20px;
    height: 907px;
    overflow: hidden;
    position: relative
}

.scope-normal-module10 .scope-normal-module10-content .scope-normal-module10-modle {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2
}

.scope-normal-module10 .scope-normal-module10-content .scope-normal-module10-content-bg {
    left: 50%;
    min-height: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 1
}

.scope-normal-module10 .scope-normal-module10-content .scope-normal-module10-info {
    padding-top: 300px;
    position: relative;
    text-align: center;
    z-index: 3
}

.scope-normal-module10 .scope-normal-module10-content .scope-normal-module10-info .scope-normal-module10-info-p1 {
    color: #222;
    font-family: HarmonyOSHans-Regular,PingFangSC-Regular,Microsoft YaHei,Arial,Helvetica,sans-serif;
    font-size: 72px;
    font-weight: 600;
    line-height: 101px;
    margin-bottom: 48px;
    text-align: center
}

.scope-normal-module10 .scope-normal-module10-content .scope-normal-module10-info .scope-normal-module10-info-button {
    align-items: center;
    background: #4b48db;
    border-radius: 8px;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    padding: 16px 16px 16px 24px
}

.scope-normal-module10 .scope-normal-module10-content .scope-normal-module10-info .scope-normal-module10-info-button .scope-normal-module10-info-button-span {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    margin-right: 16px
}

.scope-normal-module10 .scope-normal-module10-content .scope-normal-module10-info .scope-normal-module10-info-button .scope-normal-module10-info-button-icon {
    height: 18px;
    width: 18px
}

.layer {
    position: absolute
}

.layer1 {
    background: #ffe198;
    border-radius: 920px;
    filter: blur(125px);
    flex-shrink: 0;
    height: 550px;
    left: 55px;
    opacity: .5;
    top: 468px;
    transform: rotate(-140deg);
    width: 920px
}

.layer2 {
    background: #d015ff;
    border-radius: 408px;
    filter: blur(94px);
    height: 80px;
    left: 830px;
    top: 675px;
    transform: rotate(-165deg);
    width: 410px
}

.layer2,.layer3 {
    flex-shrink: 0;
    opacity: .5;
    position: absolute
}

.layer3 {
    background: #5d85ff;
    border-radius: 370px;
    filter: blur(62px);
    height: 370px;
    left: 906px;
    top: 370px;
    transform: rotate(-85deg);
    width: 190px
}

.layer4 {
    background: #82fff9;
    border-radius: 425px;
    filter: blur(94px);
    height: 330px;
    left: 460px;
    top: 400px;
    transform: rotate(-150deg);
    width: 425px
}

.layer4,.layer5 {
    flex-shrink: 0;
    opacity: .5;
    position: absolute
}

.layer5 {
    background: #7f9fff;
    border-radius: 440px;
    filter: blur(125px);
    height: 440px;
    left: 160px;
    top: 110px;
    transform: rotate(-135deg);
    width: 440px
}

.el-image__inner {
    width: 100%;
    height: 100%;
}


@keyframes animate-left-2854adfd {
    0% {
        opacity: .7;
        transform: translate(-15px) rotate(210deg)
    }

    50% {
        opacity: .9;
        transform: translate(0) rotate(210deg)
    }

    to {
        opacity: 1;
        transform: translate(15px) rotate(210deg)
    }
}

@keyframes animate-right-2854adfd {
    0% {
        opacity: .2;
        transform: translate(15px) rotate(120deg)
    }

    50% {
        opacity: .4;
        transform: translate(0) rotate(120deg)
    }

    to {
        opacity: .6;
        transform: translate(-15px) rotate(120deg)
    }
}

@keyframes animate-right2-2854adfd {
    0% {
        opacity: .2;
        transform: translate(15px) rotate(120deg)
    }

    50% {
        opacity: .4;
        transform: translate(0) rotate(120deg)
    }

    to {
        opacity: .6;
        transform: translate(-15px) rotate(120deg)
    }
}

@media screen and (width: 1440px) { 
    .index-page {
        margin-top: 100px;
    }
    .banner {
        height: 21vh;
    }
    .home-banner-wx {
        height: 50vh;
    }
}